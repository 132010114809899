import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const Video = styled.video`
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export function VideoTrack({ track, isLocal, priority = null }) {
  const ref = useRef(null)

  useEffect(() => {
    const el = ref.current
    el.muted = true
    if (track.setPriority && priority) {
      track.setPriority(priority)
    }
    track.attach(el)
    return () => {
      track.detach(el)
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null)
      }
    }
  }, [track, priority])

  // The local video track is mirrored.
  const style = isLocal ? { transform: 'rotateY(180deg)' } : {}

  return <Video ref={ref} style={style} />
}
