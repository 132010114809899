import { useEffect, useState } from 'react'
import { connect } from 'twilio-video'
import { useLocalTracks } from './useLocalTracks'
import { firebaseFunction } from '../firebase/firebaseFunction'

const useAccessToken = (roomId) => {
  const [token, setToken] = useState('')

  useEffect(() => {
    const getToken = async () => {
      const cf = firebaseFunction().httpsCallable('requestVideoCallAccessToken')
      const response = await cf({ roomName: roomId })
      setToken(response.data)
    }
    getToken()
  }, [roomId])
  return token
}

export const useRoom = (roomId) => {
  const [remoteTracks, setRemoteTracks] = useState([])
  const [connectionError, setConnectionError] = useState()
  const [isConnecting, setIsConnecting] = useState(true)
  const [didConnect, setDidConnect] = useState(false)
  const [didDisconnect, setDidDisconnect] = useState(false)
  const { videoTrack, audioTrack, videoError, audioError } = useLocalTracks()
  const accessToken = useAccessToken(roomId)

  useEffect(() => {
    const onParticipantConnected = (participant) => {
      const trackSubscribed = (track) => {
        setRemoteTracks((tracks) => [...tracks, track])
      }

      participant.on('trackSubscribed', (track) => trackSubscribed(track))

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          trackSubscribed(publication.track)
        }
      })
    }

    const onRoomDisconnected = () => {
      videoTrack.stop()
      audioTrack.stop()
      setDidDisconnect(true)
    }

    const setupRoom = async () => {
      if (!accessToken) {
        return
      }

      try {
        if (!videoTrack || !audioTrack || videoError || audioError) {
          return
        }

        const room = await connect(accessToken, {
          tracks: [videoTrack, audioTrack],
        })

        window.addEventListener('beforeunload', room.disconnect)
        window.addEventListener('pagehide', room.disconnect)
        room.participants.forEach(onParticipantConnected)
        room.on('participantConnected', onParticipantConnected)
        room.on('disconnected', onRoomDisconnected)
        setIsConnecting(false)
        setDidConnect(true)
        return () => {
          window.removeEventListener('beforeunload', room.disconnect)
          window.removeEventListener('pagehide', room.disconnect)
          room.disconnect()
        }
      } catch (error) {
        videoTrack.stop()
        audioTrack.stop()
        setConnectionError(error)
      }
    }

    setupRoom()
  }, [roomId, videoTrack, audioTrack, accessToken, videoError, audioError])

  return {
    remoteTracks,
    connectionError,
    isConnecting,
    didConnect,
    didDisconnect,
    localVideoTrack: videoTrack,
    videoError,
    audioError,
  }
}
