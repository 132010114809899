import { useEffect } from 'react'

export function AudioTrack({ track }) {
  useEffect(() => {
    if (!track) return
    document.body.appendChild(track.attach())
    return () => track.detach().forEach((el) => el.remove())
  }, [track])
  return null
}
