import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo_samen_altijd_dark.png'

export const Layout = ({ children }) => (
  <Container>
    <Image src={logo} alt={'logo'} />
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  padding: 2rem;
  height: 100%;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    padding: 1rem;
  }
`

const Image = styled.img`
  width: 400px;
  position: absolute;
  top: 2rem;
  @media only screen and (max-width: 600px) {
    width: 200px;
  }
`
