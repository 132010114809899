import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { setConfig } from './constants/serverVariables'
import firebaseConfig from './config/firebase-config.json'

import { fetchConfigInformation } from './system/ConfigBehaviour'

fetchConfigInformation().then((result) => {
  firebaseConfig.databaseURL = result.databaseUrl
  setConfig(result)
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
})
