import React, { useLayoutEffect } from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Room from './pages/Room'
import Home from './pages/Home'
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import config from './config/firebase-config.json'
import { theme } from './styles'
import Div100vh from 'react-div-100vh'

function App() {
  useLayoutEffect(() => {
    firebase.initializeApp(config)
  }, [])
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SApp className="App">
          <GlobalStyle />
          <Switch>
            <Route exact path="/404">
              <NotFound />
            </Route>
            <Route path="/:roomId">
              <Room />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </SApp>
      </ThemeProvider>
    </Router>
  )
}

const SApp = styled(Div100vh)`
  overflow: hidden;
  width: 100vw;
`

export default App

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.main.background};
  font-family:${(props) => props.theme.main.fontFamily};
  color: ${(props) => props.theme.main.textColor};
}

`
