import { useCallback, useEffect, useState } from 'react'
import Video from 'twilio-video'

export function useLocalAudioTrack() {
  const [track, setTrack] = useState(undefined)
  const [error, setError] = useState(undefined)
  useEffect(() => {
    Video.createLocalAudioTrack()
      .then((newTrack) => {
        setTrack(newTrack)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return { track, error }
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState(undefined)
  const [error, setError] = useState(undefined)
  const shouldInvertRatio = isMobile()
  const getLocalVideoTrack = useCallback(() => {
    const width = shouldInvertRatio ? 450 : 320
    const height = shouldInvertRatio ? 320 : 450

    Video.createLocalVideoTrack({
      frameRate: 24,
      width,
      height,
      name: 'camera',
    })
      .then((newTrack) => {
        setTrack(newTrack)
        return newTrack
      })
      .catch((error) => {
        setError(error)
      })
  }, [shouldInvertRatio])

  useEffect(() => {
    // Get a new local video track when the app loads.
    getLocalVideoTrack()
  }, [getLocalVideoTrack])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return { track, error }
}

export function useLocalTracks() {
  const { track: audioTrack, error: audioError } = useLocalAudioTrack()
  const { track: videoTrack, error: videoError } = useLocalVideoTrack()
  return { audioTrack, videoTrack, audioError, videoError }
}

function isMobile() {
  const match = window.matchMedia
  if (window.matchMedia) {
    const mq = match('(pointer:coarse)')
    return mq.matches
  }
  return false
}
