import React from 'react'
import { VideoTrack } from '../components/VideoTrack'
import { AudioTrack } from '../components/AudioTrack'
import { useRoom } from '../hooks/useRoom'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Layout } from '../components'
import logo from '../assets/logo_2020_no_text.svg'

function App() {
  const { roomId } = useParams()
  const {
    remoteTracks,
    connectionError,
    isConnecting,
    didConnect,
    didDisconnect,
    localVideoTrack,
    videoError,
    audioError,
  } = useRoom(roomId)

  if (videoError || audioError) {
    return (
      <Layout>
        Om te video bellen hebben we toegang tot uw camera en microfoon nodig.
      </Layout>
    )
  }

  if (didConnect && didDisconnect) {
    return <Layout>De verbinding is verbroken.</Layout>
  }

  if (connectionError) {
    return <Layout>Het lijkt er op dat deze link niet meer werkt.</Layout>
  }

  if (isConnecting) {
    return <Layout>Verbinding maken...</Layout>
  }

  return (
    <Container>
      {localVideoTrack && (
        <Self>
          <VideoTrack track={localVideoTrack} isLocal />
        </Self>
      )}
      <Participant>
        {!isConnecting && remoteTracks.length > 0
          ? remoteTracks.map((track, index) => {
              if (track.kind === 'video') {
                return <VideoTrack track={track} isLocal={false} key={index} />
              }
              if (track.kind === 'audio') {
                return <AudioTrack track={track} key={index} />
              }
              return null
            })
          : null}
      </Participant>
      <Logo src={logo} alt="logo" />
    </Container>
  )
}

const Self = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: 30%;
  height: 225px;
  max-width: 160px;
  max-height: 225px;
`

const Participant = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  z-index: 1;
`

const Container = styled.div`
  display: grid;
  grid:
    '. . .' 1fr
    'logo . self' 1fr / 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
`

const Logo = styled.img`
  width: 50%;
  grid-area: logo;
  align-self: end;
  justify-self: start;
  z-index: 0;
  margin: 0px 0px 10px 10px;
`

export default App
